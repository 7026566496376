<script setup lang="ts">
import LoganInput from './LoganInput.vue';
import {
	Button,
	FormControl,
	FormField,
	FormItem,
	Checkbox,
} from '@laam/ui/base';
import { PhEnvelopeSimple, PhDeviceMobileCamera } from '@phosphor-icons/vue';
import { useLoganStore } from '~/stores/logan';
import Loader from './Loader.vue';
interface LoginBox {
	heading: string;
	subHeading: string;
	// eslint-disable-next-line vue/require-default-prop
	otpHelpMsg?: string;
	showConfirmationText: boolean;
	showSignInWithButton: boolean;
	formName: string;
	signInWithText?: string;
}
withDefaults(defineProps<LoginBox>(), {
	signInWithText: 'Continue with',
});
const LoganStore = useLoganStore();

const timer = ref(30);
const showTimer = ref(false);
const showOtpText = ref(false);
// function handle timer decrement
const handleTimer = () => {
	showTimer.value = true;
	showOtpText.value = true;
	const intervalId = setInterval(() => {
		// Decrement the timer value by 1
		timer.value--;
		// Check if the timer has reached 0
		if (timer.value === 0) {
			// If the timer reaches 0, clear the interval
			clearInterval(intervalId);
			timer.value = 30;
			showTimer.value = false;
		}
	}, 1000);
};

// if otp is requested and otp box is open, means first otp request is made start timer
watch(
	() => LoganStore.showOtpInputBox,
	(n) => {
		if (n) {
			handleTimer();
		}
	},
);
// if resend otp is requested, resend otp will change from true -> false
// once we match this start decrementing timer
watch(
	() => LoganStore.resendOtp,
	(newValue, oldValue) => {
		if (oldValue === true && newValue === false) {
			handleTimer();
		}
	},
);

const { storeType } = useGlobalStore();
const isSubscribed = ref(false);
onMounted(() => {
	const isSubscribedLocal = localStorage.getItem('isSubscribed')
		? (JSON.parse(localStorage.getItem('isSubscribed')!) as boolean)
		: false;
	isSubscribed.value = isSubscribedLocal;
});
</script>
<template>
	<div class="flex flex-col w-full p-3xl gap-8xl logan_form">
		<div v-if="heading" class="flex flex-col headings_container gap-md">
			<p class="text-2xl font-semibold text-gray-900">{{ heading }}</p>
			<p v-if="showOtpText" class="text-sm font-normal text-gray-600">
				{{ subHeading }}
			</p>
		</div>
		<div class="flex flex-col items-center input_container gap-7xl">
			<div class="flex flex-col items-center w-full gap-3xl">
				<LoganInput />
				<div
					v-if="storeType === 'MARKETPLACE' && !isSubscribed"
					class="flex w-full items-center gap-xl text-sm font-medium text-gray-700"
				>
					<FormField
						v-slot="{ value, handleChange }"
						name="subscribeToNewsLetter"
						type="checkbox"
					>
						<Checkbox :checked="value" @update:checked="handleChange" />
						Subscribe to our newsletter
					</FormField>
				</div>
			</div>
			<div v-if="LoganStore.showOtpInputBox">
				<Button
					v-if="!showTimer"
					id="resend otp"
					class="text-sm font-semibold text-blue-700"
					variant="tertiary"
					type="submit"
					@click="LoganStore.resendOtp = true"
				>
					Resend OTP
				</Button>
				<div v-else class="w-full flex flex-col items-center gap-7xl mt-[24px]">
					<p class="text-sm text-gray-600 resend_timer">
						Resend OTP in {{ timer }} seconds
					</p>
					<p class="text-sm text-gray-600 resend_timer">{{ otpHelpMsg }}</p>
				</div>
			</div>
			<div class="flex flex-col items-center w-full gap-3xl">
				<Button
					size="lg"
					class="w-full request_otp bg-gray-800"
					type="submit"
					:form="formName"
				>
					<div v-if="!LoganStore.loading">
						<p
							v-if="LoganStore.showOtpInputBox"
							data-testid="login-confirm-button"
						>
							Confirm
						</p>
						<p v-else data-testid="request-otp-button">Request OTP</p>
					</div>
					<Loader v-else />
				</Button>
				<FormField
					v-slot="{ componentField, handleChange }"
					name="usingEmail"
					class="w-full"
				>
					<FormItem v-auto-animate class="w-full">
						<FormControl class="w-full">
							<Button
								v-if="showSignInWithButton"
								size="lg"
								class="w-full logan_change_input"
								variant="secondary"
								v-bind="componentField"
								type="button"
								@click.prevent="handleChange(!componentField.modelValue)"
							>
								<PhEnvelopeSimple v-if="!componentField.modelValue" />
								<PhDeviceMobileCamera v-else />
								{{ signInWithText }}
								<p v-if="!componentField.modelValue">Email</p>
								<p v-else>Phone</p>
							</Button>
						</FormControl>
					</FormItem>
				</FormField>
			</div>
		</div>
		<div
			v-if="showConfirmationText"
			class="flex flex-wrap items-baseline justify-center text-center gap-x px-3xl"
			:class="{ 'max-w-[500px]': !isMobileView() }"
		>
			<span class="w-full text-sm text-gray-600">
				By signing in, I confirm that I have reviewed the
				<!-- fix this -->
				<NuxtLink
					to="/privacy-policy"
					class="text-sm font-semibold text-blue-600"
					@click="LoganStore.showLoginSheet = false"
				>
					Privacy policy
				</NuxtLink>
				and
				<NuxtLink
					to="/terms-conditions"
					class="text-sm font-semibold text-blue-600"
					@click="LoganStore.showLoginSheet = false"
				>
					Terms of service
				</NuxtLink>
			</span>
		</div>
	</div>
</template>
