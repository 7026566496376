<script setup lang="ts">
import { useField } from 'vee-validate';
import TextInput from '../TextInput.vue';
import OtpInput from './OtpInput.vue';
import { useLoganStore } from '~/stores/logan';
import PhoneInput from './PhoneInput.vue';
import { PhX } from '@phosphor-icons/vue';

const usingEmailField = useField<boolean>('usingEmail').value;
const LoganStore = useLoganStore();
</script>
<template>
	<div class="flex flex-col items-center w-full gap-3xl">
		<PhoneInput v-if="!usingEmailField" :error="LoganStore.invalidInput" />
		<div v-else class="flex items-center w-full relative">
			<TextInput
				type="email"
				class="relative"
				field-name="email"
				place-holder="Enter your Email address"
				label="Email Address"
				data-testid="email-input"
				:disabled="LoganStore.showOtpInputBox"
			>
				<template
					v-if="!LoganStore.showOtpInputBox"
					#rightIcon="{ handleReset }"
				>
					<PhX
						v-if="!LoganStore.showOtpInputBox"
						:size="16"
						:color="'#A3A3A3'"
						class="absolute top-[41px] right-[2%] cursor-pointer"
						@click="handleReset"
					/>
				</template>
			</TextInput>
		</div>

		<OtpInput
			v-if="LoganStore.showOtpInputBox"
			:error="LoganStore.invalidOtp"
			field-name="otp-input"
		/>
	</div>
</template>
